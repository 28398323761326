import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bankList from "../data/banks"
import "./sba-approved-lenders.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const SBAApprovedLenders = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [searchAcceptingResults, setAcceptingSearchResults] = React.useState([])
  const [searchResults, setSearchResults] = React.useState([])
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  const [banks, setBanks] = useState(bankList.banks)
  const [filterText, setFilterText] = useState("")
  const [filterAcceptingText, setAcceptingFilterText] = useState("")

  useEffect(() => {
    const filterAcceptingBanks = banks
      .sort((a, b) => (a.Bank > b.Bank ? 1 : -1))
      .filter(bank => bank.accepting === "Yes")
      .filter(bank =>
        bank.Bank.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )

    if (filterAcceptingBanks.length == 0) {
      setAcceptingFilterText(
        "No results among the featured SBA-approved lenders currently accepting new customers. Please try again or review the full list of approved lenders below."
      )
      setAcceptingSearchResults(filterAcceptingBanks)
    } else {
      setAcceptingFilterText("")
      setAcceptingSearchResults(filterAcceptingBanks)
    }

    const filterBanks = banks
      .sort((a, b) => (a.Bank > b.Bank ? 1 : -1))
      .filter(bank => bank.accepting === "No")
      .filter(bank =>
        bank.Bank.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )

    if (filterBanks.length == 0) {
      setFilterText(
        "No results among the featured SBA-approved lenders not accepting new customers at this time. Please try again or review the full list of approved lenders below."
      )
      setSearchResults(filterBanks)
    } else {
      setFilterText("")
      setSearchResults(filterBanks)
    }
  }, [searchTerm])

  return (
    <Layout>
      <SEO title="SBA-Approved Lenders" />
      <div>
        <div className="space"></div>
        <div className="bankHeaderCard">
          <label class="bankListHeader">
            SBA-Approved Lenders Operating in Florida
          </label>
        </div>
        <div className="container primary-bg">
          <section className="container">
            <label className="bankText">
              Check back often as this list is likely to be updated often. Click
              on any of the banks below to be taken to its website.
            </label>
          </section>
          <hr />
          <section className="container">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <FontAwesomeIcon
                    className="fontIcon"
                    size="lg"
                    icon={faSearch}
                  />
                </span>
              </div>
              <input
                type="text"
                placeholder="Search banks"
                value={searchTerm}
                onChange={handleChange}
                className="form-control inputText"
              />
            </div>
          </section>
          <section className="container">
            <label className="acceptingNewClient">Accepting New Clients</label>
            {searchAcceptingResults.map((bank, index) => {
              return (
                <div className="bankCardAccepting">
                  <div class="card-body">
                    <a className="bankLink" href={bank.link} target="_blank">
                      {bank.Bank}
                    </a>
                  </div>
                  <div class="card-footer">Accepting New Clients</div>
                </div>
              )
            })}
            <div>
              <label className="bankText" >{filterAcceptingText}</label>
            </div>
          </section>
          <hr />
          <section className="container">
            <label className="acceptingNewClient">
              Not Accepting New Clients
            </label>
            {searchResults.map((bank, index) => {
              return (
                <div className="bankCard">
                  <a className="bankLink" href={bank.link} target="_blank">
                    {bank.Bank}
                  </a>
                </div>
              )
            })}
             <div>
              <label className="bankText" >{filterText}</label>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default SBAApprovedLenders
